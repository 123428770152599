import React, { useEffect } from "react"
import Cookies from "universal-cookie"

const cookies = new Cookies()

const ReferralStateContext = React.createContext<null | string>(null)

/**
 *
 * Referrals look like :
 * referral = 'john-smith-10'
 *
 * And appear in the url like: www.purplecowinternet.com/#john-smith-10
 */

async function referralValid(referral: string) {
  if (!referral) {
    return false
  }
  try {
    referral = referral.split("/")[0];
    let result = await fetch(
      `https://jp5vfaodlc.execute-api.us-east-2.amazonaws.com/CheckSalesforceReports?var=${referral}`
    ).then((res) => res.json())
    if (!result.referralValid) {
      let referralArray = referral.split('-');
      if (referralArray.length > 2) {
        referral = `${referralArray[0]}-${referralArray[1]}`;
        result = await fetch(
          `https://jp5vfaodlc.execute-api.us-east-2.amazonaws.com/CheckSalesforceReports?var=${referral}`
        ).then((res) => res.json())
      }
    }
    return result.referralValid
  } catch (e) {
    return false;
  }
}

async function referralValidWithResponse(referral: string) {
  if (!referral) {
    return false
  }
  try {
    referral = referral.split("/")[0];
    let result = await fetch(
      `https://jp5vfaodlc.execute-api.us-east-2.amazonaws.com/CheckSalesforceReports?var=${referral}`
    ).then((res) => res.json())
    if (!result.referralValid) {
      let referralArray = referral.split('-');
      if (referralArray.length > 2) {
        referral = `${referralArray[0]}-${referralArray[1]}`;
        result = await fetch(
          `https://jp5vfaodlc.execute-api.us-east-2.amazonaws.com/CheckSalesforceReports?var=${referral}`
        ).then((res) => res.json())
      }
    }
    return result
  } catch (e) {
    return false;
  }
}

export const hideCouponHeader = (ref: any) => {
  if (!ref) {
    return false;
  }
  ref = ref.toLowerCase();
  return ref.includes('/n');

}

export const getCouponCode = () => {
  let coupon = cookies.get("coupon");
  if (!coupon) {
    return "COOLPERSONCREDIT";
  }
  return coupon;
}

export const bannerMessage= () =>{
    let bannerMessage:any = cookies.get("bannerMessage");
    return bannerMessage ? bannerMessage : "";
}

export const ReferralProvider: React.FunctionComponent = ({ children }) => {
  const [state, setState]: any = React.useState<null | string>(null)

  useEffect(() => {
    let referral = window.location.hash.split("#")[1]

    if (!referral) {
      referral = cookies.get("referral")
    }
    referralValidWithResponse(referral).then((response) => {
      if (response?.referralValid) {
        let expires = new Date()
        expires.setTime(expires.getTime() + 2 * 60 * 60 * 1000)
        cookies.set("referral", referral, { expires })
        cookies.set("bannerMessage", response?.banner ? response?.banner : "", { expires })
        cookies.set("coupon", response?.couponCode, { expires })
        setState(referral)
      }
    })
  }, [])

  const updateReferralState = (referral: string) => {
    if (referral) {
      const existId = cookies.get("referral");
      referral = existId ? existId : referral;
      referralValidWithResponse(referral).then((response) => {
        if (response?.referralValid) {
          let expires = new Date()
          expires.setTime(expires.getTime() + 2 * 60 * 60 * 1000)
          cookies.set("referral", referral, { expires })
          cookies.set("coupon", response?.couponCode, { expires })
          setState(referral)
        }
      })
    }
  };

  return (
    <ReferralStateContext.Provider value={{ state, updateReferralState }}>
      {children}
    </ReferralStateContext.Provider>
  )
}

let Wrapper = ({ element }: { element: React.ReactChild }) => {
  return <ReferralProvider>{element}</ReferralProvider>
}

export default Wrapper

export function useReferralState() {
  const state :any = React.useContext(ReferralStateContext)
  return state?.state;
}

export function useUpdateReferralState() {
  const  state : any = React.useContext(ReferralStateContext);
  return state?.updateReferralState;
}

export function getReferralLink() {
  let referral = cookies.get("referral");
  if (referral) {
    return referral;
  }
  return '';
}